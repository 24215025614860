import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "dropdown",
      "style": {
        "position": "relative"
      }
    }}>{`Dropdown`}</h1>
    <p>{`Utilizado para revelar uma lista de opções ou comandos ocultos. No cabeçalho, é geralmente utilizado para navegação, onde uma ação leva o usuário para outra página.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Não remova itens da lista se essa ação não estiver disponível. Neste caso, mantenha o item desabilitado.`}</li>
      <li parentName="ul">{`Não coloque muitos itens na lista. Caso precise de muitas opções, considere utilizar outro tipo de cabeçalho/menu.`}</li>
    </ul>
    <h2 {...{
      "id": "exemplo",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplo`}</h2>
    <Demo src='pages/components/dropdown/DropdownExample' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      